body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--mui-palette-common-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.spinnerWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePickerField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.layout .DatePickerRoot .react-datepicker-popper {
  z-index: 4;
}

.uploadBtn > span {
  width: 50px;
  height: 48px !important;
  padding: 0;
}

.fileLinkWrap {
  width: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.fieldArray {
  &__actions {
    padding-top: 15px;
  }
  &__deviceActions {
    padding-top: 20px !important;
  }
  button {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: 48px !important;
  }
}

.hiddenHeaderTable {
  div[role='table'] > div:nth-child(1) {
    display: none;
  }
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.flexLine {
  display: flex;
  align-items: center;
}

.uploadBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.spTable {
  div[role='rowgroup'] {
    height: calc(100vh - 420px) !important;
  }
}

.serviceProvider button {
  margin-top: 15px;
  height: 49px !important;
}

.invalidPrefix > div::before {
  background: var(--mui-palette-error-main) !important;
}

.langs {
  display: flex;
  div {
    cursor: pointer;
    margin: 5px;
  }
}

.productRejectReason {
  color: var(--mui-palette-error-main);
}

.activeBell {
  position: relative;
}

.activeBell_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--mui-palette-error-main);
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 9px;
  text-align: center;
  font-weight: 700;
}

.cell_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.MuiTableHead-root {
  padding-left: 0 !important;
  z-index: 1;
}

.ReactVirtualized__Grid__innerScrollContainer .MuiBox-root {
  padding-left: 0;
}

.MuiList-root {
  display: flex;
  flex-direction: column;
}

@-moz-document url-prefix() {
  .ConfigForm-ColumnList {
    padding-right: 20px;
  }
}
