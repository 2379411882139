.dataTableWrap {
  word-wrap: break-word;
  word-break: break-all;
  &::-webkit-scrollbar {
    display: none !important;
  }
  div[role='table'] > div:nth-child(1) > div[role='row'] {
    cursor: default !important;
  }
  div[role='row'] {
    background-color: #f9f9f9;
    cursor: default;
  }
  div[role='row']:hover {
    background-color: #f9f9f9 !important;
    //cursor: pointer;
  }
  div[role='rowgroup'] div[role='row'] {
    background-color: #fff;
  }
  div[role='rowgroup'] {
    > div > div {
      scrollbar-width: none;
    }
  }
}

.theme-kz {
  .selectedAppMenu button {
    color: #af9fda !important;
  }

  div[role='rowgroup'] {
    .Mui-checked,
    .MuiCheckbox-indeterminate {
      span {
        background: #6e6681;
      }

      path {
        stroke: white;
      }
    }
  }
}

.theme-uz div[role='rowgroup'] {
  .Mui-checked,
  .MuiCheckbox-indeterminate {
    span {
      background: #67ac5c;
    }
  }
}

.theme-az button[style*='background: rgb(246, 244, 46)'] {
  background-color: #00b5e2 !important;
  color: white !important;
}

.theme-kz button[style*='background: rgb(246, 244, 46)'] {
  background-color: #614c77 !important;
  color: white !important;
}
.theme-am button[style*='background: rgb(246, 244, 46)'] {
  background-color: #2b5d80 !important;
  color: white !important;
}

button[type='submit'] {
  color: white;
}
