.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  .header {
    z-index: 1;
    :global(.AppMainMenu-Logo) {
      display: none;
    }
  }
  .page {
    .spinner {
      height: calc(100vh - 200px);
    }
    height: auto;
    position: relative;
    padding: 16px;
    padding-top: 32px;
    box-sizing: border-box;
    &__content {
      border: 1px solid #f2f2f2;
      box-shadow: 2px 0 15px rgba(0, 0, 0, 0.05);
      position: relative;
      background-color: var(--mui-palette-background-default);
      padding: 20px;
      box-sizing: border-box;
    }
    @media screen and (max-height: 800px) {
      padding-bottom: 100px;
    }
  }
}
